/* YourComponent.css */
.desktop {
  display: flex;
  /* default style for desktop */
}

.mobile {
  display: none; /* default style for mobile */
}

@media (max-width: 768px) {
  .desktop {
    display: none; /* override style for mobile devices */
  }

  .mobile {
    display: block; /* override style for desktop devices */
  }
}
