/* YourComponent.css */
.login-screen {
  width: 40%; /* Without quotes */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .login-screen {
    width: 90%; /* Without quotes */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
