/* Buttons */
.btn-inv {
    padding: 0.75rem 1rem; /* Adjusted padding */
    border-radius: 0.25rem; /* rounded */
    border-color: white;
    
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    border: none;
    cursor: pointer;
    font-weight: 500;
    background-color: green; /* Changed to green color */
  }
  
  .btn-inv:active {
    transform: scale(0.95);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }


.custom-btn {
    font-weight: bold;
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 30px;
  }

  .custom-btn-apply-filters {
    color: white;
    background-color: green;
    font-weight: 500;
  }
  
  .custom-btn-blue {
    background-color: #1184e2; /* Blue color */
    color: #fff;
    min-width: 120px;
    text-align: center;
    letter-spacing: 0.5px;
  }

  .custom-btn-filter {
    background-color: #303e49; /* Blue color */
    color: #fff;
    min-width: 120px;
    text-align: center;
    letter-spacing: 0.5px;
  }
  
  .custom-btn-red {
    background-color: #d80e0e; /* Red color */
    color: #fff;
  }

  .custom-btn-red-filter {
    background-color: #d80e0e; /* Red color */
    color: #fff;
  }

  .custom-btn-reset-filters {
    /* Add your styles for the reset button here */
    background-color: #d80e0e; /* Example style */
    color: white;
    


}

.btns {
    margin-top: 15px;

}

  /* FILTERS */

  .filters {
    min-height: 100px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;


  }

/* TABEL */
table {
    border-collapse: collapse;
    width: 100%;

  }
  
  /* Style table headers */
  thead th {
    text-align: left;
    padding: 12px;
    color: black;
    
    background-color: #ffffff;
    /* border-bottom: 2px solid #d4d4d4; */
    border-width: 1px;
    border-color: #333;
  }
  
  /* Style table body rows */
  tbody td {
    padding: 12px;
    border-bottom: 1px solid #ffffff;
    border-width: 1px;
    border-color: #333;
    font-weight: 500;
  }
  
  /* Highlight even rows with a light background color */
  tbody tr:nth-child(even) {
    background-color: #ffffff;
   
  }
  
  /* Add a border to the last row to separate it from the footer */
  tbody tr:last-child {
    border-bottom: 2px solid #252121;
  }
  
  /* Style the footer row, if applicable */
  tfoot td {
    background-color: #f2f2f2;
    border-top: 2px solid #d4d4d4;
  }
  
  /* Optional: Style the table header text */
  th {
    font-weight: bold;
    color: #333;
  }


  .add-device-section, .filters-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Spacing between input fields */
    margin-bottom: 20px;
  }
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    .add-device-section, .filters-section {
      flex-direction: column;
    }
  }
  
  /* Style for each input field to take full width on mobile */
  .add-device-section input, .filters-section input {
    flex: 1 1 200px; /* Adjust the min-width as per your design */
  }
  
  @media (max-width: 768px) {
    .add-device-section input, .filters-section input {
      width: 100%;
    }
  }
  