.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  margin-left: 550px;
  flex-direction: column;
}

.spinner {
  animation: rotate 2s linear infinite;
  width: 100px;

}

.path {
  stroke: #007bff;
  strokelinecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
