.app-container {
  display: flex;
}

.side-menu-container {
  display: flex;
  z-index: 999;
}

.side-menu {
  padding-top: 20px;
  width: 120px;
  height: 100vh;
  background: #000031;
  /* background: linear-gradient(90deg, rgba(0,5,78,1) 0%, rgba(0,0,49,1) 100%); */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.right-side-menu {
  width: 160px; /* Adjust the width as needed */
  /* background-color: #4a6572; */
  background: linear-gradient(
    90deg,
    rgba(0, 5, 78, 1) 0%,
    rgba(0, 0, 49, 1) 100%
  );
  padding-top: 20px;
  /* border-radius: 8px; */
  color: white;
}

.right-side-menu-box {
  overflow: auto;
  max-height: 70%;
}

.main-content {
  flex-grow: 1;
}

.nav-link {
  text-decoration: none;
  display: block;
  padding: 8px 16px;
  transition: background-color 0.3s;
  background-color: #00054e;
  font-size: 19px;
  font-weight: 400;
}

.nav-link:visited {
  background-color: #00054e;
  font-weight: 400;
}

.nav-link-right {
  text-decoration: none;
  display: block;
  padding: 8px 16px;
  transition: background-color 0.3s;
  /* background: linear-gradient(
    90deg,
    rgba(0, 5, 78, 1) 0%,
    rgba(0, 0, 49, 1) 100%
  ); */
  text-align: center;
  overflow: auto;
}

.nav-link-right:hover {
  transition: background-color 0.3s;
  background-color: #f9aa33;
  border-radius: 4px;
}

.nav-link:hover {
  background-color: #f9aa33;
  border-radius: 4px;

  /* Set your desired hover background color */
}

.right-menu-item {
  background-color: #00054e; /* Set your desired background color */
  transition: background-color 0.3s;
  font-weight: 400;
  font-size: 19px;
  color: white;
}

.right-menu-item:hover {
  background-color: #f9aa33;
  border-radius: 4px;
  /* Set your desired hover background color */
}

.svgIconList {
  width: 20px;

  color: white;
}

.svgIconList:visited {
  color: white;
  background-color: black;
  z-index: inherit;
}

.svgIconList:hover {
  width: 20px;
}
